<template>
    <div
        class="sidebar"
        :data-background-color="backgroundColor"
        :data-active-color="activeColor"
    >
        <div class="sidebar-wrapper" id="style-3">
            <div class="logo">
                <a href="/dashboard/home" class="simple-text">
                    <div class="logo-img">
                        <img :src="logo" alt="" />
                    </div>
                    {{ title }}
                </a>
            </div>
            <slot></slot>
            <ul class="nav">
                <slot name="links">
                    <sidebar-link
                        v-for="(link, index) in links"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon"
                    >
                    </sidebar-link>
                </slot>
            </ul>
            <moving-arrow :move-y="arrowMovePx"></moving-arrow>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, watch, provide, computed } from 'vue';
import { useRoute } from 'vue-router';
import MovingArrow from './MovingArrow.vue';

export default {
    name: 'SidebarComponent',
    components: {
        MovingArrow,
    },
    props: {
        logo: {
            type: String,
            default: '@/assets/img/dhc-logo.png',
        },
        title: {
            type: String,
            default: 'Club Assist SaaS',
        },
        backgroundColor: {
            type: String,
            default: 'black',
            validator: value => ['white', 'black', 'darkblue'].includes(value),
        },
        activeColor: {
            type: String,
            default: 'success',
            validator: value =>
                ['primary', 'info', 'success', 'warning', 'danger'].includes(
                    value
                ),
        },
        sidebarLinks: {
            type: Array,
            default: () => [],
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
    },
    setup(props) {
        const route = useRoute();

        const linkHeight = ref(65);
        const activeLinkIndex = ref(0);
        const links = ref([]);

        const arrowMovePx = computed(
            () => linkHeight.value * activeLinkIndex.value
        );

        const findActiveLink = () => {
            links.value.forEach((link, index) => {
                if (
                    link &&
                    typeof link.isActive === 'function' &&
                    link.isActive()
                ) {
                    activeLinkIndex.value = index;
                }
            });
        };

        const addLink = link => {
            if (link && typeof link.isActive === 'function') {
                links.value.push(link);
                findActiveLink();
            }
        };

        const removeLink = link => {
            const index = links.value.indexOf(link);
            if (index > -1) {
                links.value.splice(index, 1);
            }
        };

        provide('autoClose', props.autoClose);
        provide('addLink', addLink);
        provide('removeLink', removeLink);

        watch(
            () => props.sidebarLinks,
            newVal => {
                links.value = newVal.map(link => ({
                    ...link,
                    isActive: () => route.path === link.path,
                }));
                findActiveLink();
            },
            { immediate: true }
        );

        onMounted(() => {
            watch(() => route.path, findActiveLink, { immediate: true });
        });

        return {
            links,
            arrowMovePx,
        };
    },
};
</script>

<style scoped></style>
